import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { selectAssociatedRetailerLocation } from '@lib/core/retailers/selectors/retailerLocation';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_PROMOTIONS_QUERY } from '@lib/core/service/consts';
import { RootState } from '@lib/core/service/types/appStateType';
import {
  IFetchProductsLimitedOptions,
  IFetchProductsOptions,
  IProductsRequestMeta,
  IProductsResponse,
} from '@lib/core/service/types/interface';
import { shuffleProductsInsideRankGroups } from '@lib/core/service/utils';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { IProductCatalogState, IProductsLimitedRequestData } from '@lib/tools/productCatalog/types/types';
import { PRICE, PRODUCT_CATEGORY_QUERY, PROMOTION_FEATURED_SLUG } from '@lib/tools/shared/helpers/consts';

export const initialState: IProductCatalogState = {
  featuredProducts: [],
  filtersData: {},
  isFeaturedProductsLoading: false,
  isProductsRequestLoading: false,
  lastDataLoadedTime: 0,
  lastFeaturedProductsLoadedTime: 0,
  productsData: {
    count: undefined,
    next: '',
    previous: '',
    results: [],
  },
  productsDataLimited: {
    first_priority: [],
    second_priority: [],
    third_priority: [],
  },
  productsRequestError: '',
  staticMatchedProducts: [],
};

export const fetchProductsListsExtended = createAsyncThunk(
  'table/fetchProductsLists',
  async ({ params }: IFetchProductsOptions) => {
    const response = await getProductsListData({ ...params });
    return { ...response, results: shuffleProductsInsideRankGroups(response.results) };
  },
);

export const fetchProductsListsFeatured = createAsyncThunk(
  'table/fetchProductsListsFeatured',
  async ({ params }: IFetchProductsOptions) => {
    const response = await getProductsListData({
      ...params,
      [GPRL_PROMOTIONS_QUERY]: PROMOTION_FEATURED_SLUG,
    });
    return { ...response, results: shuffleProductsInsideRankGroups(response.results) };
  },
);

export const fetchProductsListsLimited = createAsyncThunk(
  'table/fetchProductsListsLimited',
  async ({ params }: IFetchProductsLimitedOptions, thunkAPI) => {
    const { getState } = thunkAPI;
    const state: RootState = getState() as RootState;
    const associatedRTL = selectAssociatedRetailerLocation(state)?.slug;
    const requestParams = { ...params };
    if (associatedRTL) {
      requestParams[ASSOCIATED_RETAILER_SLUG_QUERY] = associatedRTL;
    }
    return await getLimitedProductsListData({
      ...requestParams,
    });
  },
);

export const productCatalogSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchProductsListsLimited.pending, state => {
      state.isProductsRequestLoading = true;
      state.lastDataLoadedTime = 0;
      state.productsDataLimited = initialState.productsDataLimited;
    });
    builder.addCase(fetchProductsListsLimited.fulfilled, (state, action: { payload: IProductsLimitedRequestData }) => {
      const { payload } = action;
      state.isProductsRequestLoading = false;
      state.productsDataLimited = payload;
      state.lastDataLoadedTime = new Date().getTime();
    });
    builder.addCase(fetchProductsListsLimited.rejected, (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }

      state.isProductsRequestLoading = false;
      state.lastDataLoadedTime = new Date().getTime();
    });

    builder.addCase(
      fetchProductsListsExtended.pending,
      (state, action: { meta: IProductsRequestMeta; payload: IProductsResponse }) => {
        const { meta: { arg: { isPagination = false } = {} } = {} } = action;
        if (!isPagination) {
          state.productsData = {
            count: undefined,
            next: '',
            previous: '',
            results: [],
          };
        }
        state.isProductsRequestLoading = true;
      },
    );
    builder.addCase(
      fetchProductsListsExtended.fulfilled,
      (state, action: { meta: IProductsRequestMeta; payload: IProductsResponse }) => {
        const {
          payload: { results = [], stats = {} } = {},
          meta: {
            arg: {
              shouldShuffleResponse,
              isPagination,
              isForCollectFilters,
              params: { [PRODUCT_CATEGORY_QUERY]: productCategory } = {},
            } = {},
          } = {},
        } = action;
        if (!isForCollectFilters) {
          const newArray = shouldShuffleResponse ? shuffleProductsInsideRankGroups(results) : results;
          state.productsData = {
            ...action.payload,
            results: isPagination ? [...state.productsData.results, ...newArray] : newArray,
          };
        }
        state.isProductsRequestLoading = false;
        state.lastDataLoadedTime = isForCollectFilters ? 0 : new Date().getTime();
        const currentData = state.filtersData[productCategory] || {};
        const filtersDataFull = { [PRICE]: isForCollectFilters ? stats[PRICE] : currentData[PRICE] };
        Object.keys(stats).forEach(filterDataKey => {
          if (filterDataKey !== PRICE) {
            filtersDataFull[filterDataKey] = Object.keys(stats[filterDataKey]).map(filterValueKey => ({
              active: false,
              name: stats[filterDataKey][filterValueKey]?.name,
              value: filterValueKey,
            }));
          }
        });
        state.filtersData = { [productCategory]: filtersDataFull };
      },
    );
    builder.addCase(fetchProductsListsFeatured.pending, state => {
      state.isFeaturedProductsLoading = true;
    });
    builder.addCase(fetchProductsListsFeatured.rejected, state => {
      state.isFeaturedProductsLoading = false;
    });
    builder.addCase(
      fetchProductsListsFeatured.fulfilled,
      (state, action: { meta: IProductsRequestMeta; payload: IProductsResponse }) => {
        const { payload: { results = [] } = {} } = action;
        state.isFeaturedProductsLoading = false;
        state.featuredProducts = results;
        state.lastFeaturedProductsLoadedTime = new Date().getTime();
      },
    );
    builder.addCase(fetchProductsListsExtended.rejected, (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }
      state.isProductsRequestLoading = false;
    });
    builder.addCase(actionResetCatalogFilters, state => {
      state.filtersData = initialState.filtersData;
    });
  },
  initialState,
  name: 'table',
  reducers: {
    resetProductCatalogState: () => initialState,
    setStaticMatchedProducts: (state, action) => {
      state.staticMatchedProducts = action.payload;
    },
  },
});

export default productCatalogSlice.reducer;
export const { resetProductCatalogState, setStaticMatchedProducts } = productCatalogSlice.actions;
