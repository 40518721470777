import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { useSearchTags } from '@lib/core/service/hooks/useSearchTags';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import {
  actionApplyCatalogFilter,
  actionCloseCatalogFilter,
  actionOpenCatalogFilter,
  actionResetFilterValuesUpdatedTime,
} from '@lib/tools/filterManager/slices/productFilter';
import { setPriceRangeValues } from '@lib/tools/filterManager/slices/rangeFilterSlice';
import { currencies } from '@lib/tools/locale/utils/consts';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import {
  CHARACTERISTICS,
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PROMOTION_TOGGLE,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  FORMAT,
  ORIGINS,
  PRICE,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
  STYLE,
  VARIANT_SET_EMBEDDED,
  VARIANT_SET_FULLPAGE,
  VARIANT_SET_JOURNAL,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { useDebounceEffectSkipFirst } from '@lib/tools/views/hooks/useDebounceEffect';

import FilterComponent from '@components/web/src/components/Catalog/Filter/FilterComponent/FilterComponent';
import FilterSection from '@components/web/src/components/Catalog/Filter/FilterSection';

export type IFilterProps = {
  handleApply: (args: IHandleProductsRequestParams) => void;
  isSearchEnabled?: boolean;
  isJournalVariant?: boolean;
  isLayoutHidden?: boolean;
  additionalCharacters?: any;
  handleCancelCharacter?: (string) => void;
  productCategory?: TProductCategory;
  additionalFiltersEnabled?: Record<string, boolean>;
  disabledFilters?: Record<string, boolean>;
};

const FilterPage: FC<IFilterProps> = ({
  productCategory,
  handleApply,
  isSearchEnabled,
  isLayoutHidden = false,
  isJournalVariant = false,
  handleCancelCharacter,
  additionalCharacters = [],
  additionalFiltersEnabled = {},
  disabledFilters = {},
}) => {
  const dispatch = useDispatch();

  const selectedTags = useSearchTags();
  const { isFilterOpened } = useCatalogFilter();
  const {
    productFilters,
    productFilters: {
      range: { defaultMax },
      sublist: { rating },
    },
  } = useTypedSelector(state => state);
  const { filtersData, isProductsRequestLoading, itemsCount } = useProductCatalog();

  const allTags = [...additionalCharacters, ...selectedTags];

  const currentProductCategoryFiltersData = filtersData[productCategory] || {};
  const currentProductCategoryOriginsData = currentProductCategoryFiltersData[ORIGINS] || [];
  const currentProductCategoryCharacteristicsData = currentProductCategoryFiltersData[CHARACTERISTICS] || [];
  const currentProductCategoryFormatsData = currentProductCategoryFiltersData[FORMAT] || [];
  const currentProductCategoryStylesData = currentProductCategoryFiltersData[STYLE] || [];
  const { min: minPrice, max: maxPrice } = currentProductCategoryFiltersData[PRICE] || {};
  const currentProductCategoryFiltersDataLoaded = !!filtersData[productCategory];
  const ratingFiltersData = useMemo(
    () =>
      Object.values(rating)
        .map(item => ({
          active: item.isActive,
          name: item.name,
          value: item.value,
        }))
        .sort((a, b) => Number(a.value) - Number(b.value)) || [],
    [rating],
  );

  const {
    filterManager: { updatedFilterValuesTime },
    searchText: { searchTextValue },
  } = useTypedSelector(state => state.productFilters);

  useDebounceEffectSkipFirst(
    () => {
      handleApply({ isPagination: false });
    },
    [searchTextValue],
    1000,
  );

  useEffect(() => {
    if (maxPrice && maxPrice !== defaultMax && (!disabledFilters || !disabledFilters[FILTER_TYPE_PRICE_RANGE])) {
      dispatch(
        setPriceRangeValues({
          defaultMax: Math.ceil(maxPrice),
          defaultMin: Math.floor(minPrice),
          newMax: Math.ceil(maxPrice),
          newMin: Math.floor(minPrice),
        }),
      );
    }
  }, [minPrice, maxPrice]);

  const newData: Record<string, any>[] = useMemo(() => {
    // ToDo [kukharets] low prior challenge => type this
    const filtersDataParsed: any[] = [];

    if (additionalFiltersEnabled[FILTER_TYPE_CHARACTER_TOGGLE]) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_CHARACTER_TOGGLE,
      });
    }

    if (additionalFiltersEnabled[FILTER_TYPE_PROMOTION_TOGGLE]) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_PROMOTION_TOGGLE,
      });
    }

    if (additionalFiltersEnabled[FILTER_TYPE_SHOW_FAVORITES]) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_SHOW_FAVORITES,
      });
    }

    if (additionalFiltersEnabled[FILTER_TYPE_RATING]) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_RATING,
        listContent: ratingFiltersData,
      });
    }

    if (maxPrice && (!disabledFilters || !disabledFilters[FILTER_TYPE_PRICE_RANGE])) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_PRICE_RANGE,
        listContent: [
          {
            currency: currencies.EUR,
            max: maxPrice,
            min: minPrice,
          },
        ],
      });
    }

    if (currentProductCategoryOriginsData.length) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_ORIGIN,
        listContent: currentProductCategoryOriginsData,
      });
    }

    if (currentProductCategoryStylesData.length && productCategory === PRODUCT_CATEGORY_WINE) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_STYLE,
        listContent: currentProductCategoryStylesData,
      });
    }

    if (currentProductCategoryCharacteristicsData.length) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_CHARACTERISTICS,
        listContent: currentProductCategoryCharacteristicsData,
      });
    }

    if (currentProductCategoryFormatsData.length && productCategory === PRODUCT_CATEGORY_COFFEE) {
      filtersDataParsed.push({
        filterType: FILTER_TYPE_FORMAT,
        listContent: currentProductCategoryFormatsData,
      });
    }
    return filtersDataParsed;
  }, [filtersData]);

  const handleCloseFilter = () => {
    dispatch(actionCloseCatalogFilter());
  };

  const handleOpenFilter = () => {
    dispatch(actionOpenCatalogFilter());
  };

  const handleApplyFilter = () => {
    dispatch(actionApplyCatalogFilter());
  };

  useEffect(() => {
    if (!currentProductCategoryFiltersDataLoaded) {
      // 1. The first request to collect all data for filters
      // 2. Applying preselected filters
      (async () => {
        await handleApply({ isForCollectFilters: true });
        handleApplyFilter();
      })();
    }
  }, [currentProductCategoryFiltersDataLoaded]);

  useEffect(() => {
    if (updatedFilterValuesTime) {
      // By clicking the Apply button or deleting a tag,
      // we update updatedFilterValuesTime and make a new product request
      handleApply({});
    }
  }, [updatedFilterValuesTime]);

  useEffect(() => {
    // Reset updatedFilterValuesTime to avoid extra request next visit
    // when we already collected data for filter and applied preselected filters
    return () => {
      dispatch(actionResetFilterValuesUpdatedTime());
    };
  }, []);

  if (isLayoutHidden) {
    return null;
  }

  const designSetVariant = isApplicationKiosk ? VARIANT_SET_EMBEDDED : VARIANT_SET_FULLPAGE;

  return (
    <>
      {(isApplicationKiosk || !isFilterOpened) && (
        <FilterSection
          filterTags={allTags}
          handleApplyFilter={handleApplyFilter}
          handleCancelCharacter={handleCancelCharacter}
          handleCloseFilter={handleCloseFilter}
          handleOpenFilter={handleOpenFilter}
          isFilterOpened={isFilterOpened}
          isFilterReady={currentProductCategoryFiltersDataLoaded}
          isProductsRequestLoading={isProductsRequestLoading}
          isSearchEnabled={isSearchEnabled}
          itemsCount={itemsCount}
          productCategory={productCategory}
          searchTextValue={searchTextValue}
          variant={isJournalVariant ? VARIANT_SET_JOURNAL : designSetVariant}
        />
      )}
      {isFilterOpened && (
        <FilterComponent
          data={newData}
          handleClose={handleCloseFilter}
          isJournalVariant={isJournalVariant}
          variant={designSetVariant}
          handleApply={() => {
            MixpanelTracker.events.productCatalogFilter(productFilters);
            handleApplyFilter();
          }}
        />
      )}
    </>
  );
};

export default FilterPage;
