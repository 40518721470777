import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setShowTastePathStartPage } from '@lib/core/service/slices';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
  actionSetRegisterPageScenarioData,
} from '@lib/core/service/slices/technical/registerPageScenario';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

export const useAuthFeatureClick = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isUserAuthenticated } = useUser();
  const { isEnableTastePathAddon } = useAddons();

  const handleAuthFeatureClick = isUserAuthenticated
    ? null
    : (payload?: TRegisterPageScenarioState) => {
        if (isEnableTastePathAddon) dispatch(setShowTastePathStartPage(false));

        dispatch(
          actionSetRegisterPageScenarioData(payload || { scenario: RegisterPageScenarios.ProductCardIconClick }),
        );
        navigate(prependBasename(PAGES.vinhood.registration));
      };

  return { handleAuthFeatureClick };
};
