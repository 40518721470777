import { useEffect, useState } from 'react';

import { getRecipesData } from '@lib/core/productAttributes/utils';
import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  CLASSIC_RECIPE_TYPE_SLUG,
  HEALTHY_RECIPE_TYPE_SLUG,
  INGREDIENT_RECIPE_TYPE_SLUG,
} from '@lib/tools/shared/helpers/consts';

import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';

export const useRecipes = (productCharacterId: string, productCategory: TProductCategory) => {
  const { classicRecipe, healthyRecipe, ingredientRecipe } = localeCommon.productPage;

  const [recipesData, setRecipesData] = useState<IRecipe[] | null>(null);
  const [isRecipesDataLoading, setIsRecipesDataLoading] = useState(false);

  useEffect(() => {
    const fetchRecipesData = async () => {
      setIsRecipesDataLoading(true);

      const recipes = await Promise.all(
        [CLASSIC_RECIPE_TYPE_SLUG, HEALTHY_RECIPE_TYPE_SLUG, INGREDIENT_RECIPE_TYPE_SLUG].map(recipeType =>
          getRecipesData(recipeType, productCharacterId, productCategory),
        ),
      );

      const formattedRecipesData = recipes
        .filter(recipe => recipe.length > 0)
        .map(recipe => {
          const recipeInfo = recipe[0];
          const { recipe_type: recipeType, image, name: title } = recipeInfo;

          let subtitle = '' as ILocaleText;
          if (recipeType === CLASSIC_RECIPE_TYPE_SLUG) {
            subtitle = classicRecipe;
          } else if (recipeType === HEALTHY_RECIPE_TYPE_SLUG) {
            subtitle = healthyRecipe;
          } else if (recipeType === INGREDIENT_RECIPE_TYPE_SLUG) {
            subtitle = ingredientRecipe;
          }

          return { image, subtitle, title };
        });

      setRecipesData(formattedRecipesData);
      setIsRecipesDataLoading(false);
    };

    if (!isRecipesDataLoading && productCharacterId && productCategory) {
      fetchRecipesData();
    }
  }, [productCharacterId, productCategory]);

  return { isRecipesDataLoading, recipesData };
};
