import styled from 'styled-components';

export const JournalProductListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;

  .infinite-scroll-component__outerdiv {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .infinite-scroll-component {
    padding: 0 16px 120px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%;
  }
`;

export const SkeletonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const EndOfList = styled.div`
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const NoProductsWrapper = styled.div`
  width: 100%;
  padding: 56px 0 0 16px;
`;
