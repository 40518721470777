import React, { FC } from 'react';

import * as S from '@components/web/src/foundations/Image/Image.styles';

export interface ImageProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  borderRadius?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  tabIndex?: number;
  role?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

export const Image: FC<ImageProps> = ({
  src,
  alt,
  width = '100%',
  height = '100%',
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  borderRadius = '0',
  objectFit = 'cover',
  tabIndex,
  role = 'img',
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyDown,
  onError,
  ...rest
}) => {
  return (
    <S.StyledImageWrapper
      $borderRadius={borderRadius}
      $height={height}
      $maxHeight={maxHeight}
      $maxWidth={maxWidth}
      $minHeight={minHeight}
      $minWidth={minWidth}
      $width={width}
      data-testid="image-wrapper"
      role={onClick ? role : undefined}
      tabIndex={onClick ? tabIndex : undefined}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      <S.StyledImage
        $objectFit={objectFit}
        alt={alt}
        data-testid={`image-${alt.replace(/\s+/g, '-').toLowerCase()}`}
        src={src}
        onError={onError}
      />
    </S.StyledImageWrapper>
  );
};
