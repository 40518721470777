import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';
import { selectWishlistProductIds } from '@lib/core/users/selectors/wishlist';
import { userWishListApiUrlCreator } from '@lib/core/users/slices/urls';

export interface IUpdateWishlistParams {
  productId: string;
}

interface IState {
  isLoading: boolean;
  isWishlistLoaded: boolean;
  data: TProductInstance[];
}

export const initialWishlistState: IState = {
  data: [],
  isLoading: false,
  isWishlistLoaded: false,
};

export const fetchAllWishlist = createAsyncThunk('wishlist/fetchAllWishlist', async _ => {
  const json = await request(userWishListApiUrlCreator());

  return json.results;
});

export const updateWishlist = createAsyncThunk(
  'wishlist/updateWishlist',
  async ({ productId }: IUpdateWishlistParams, { getState }: any) => {
    const isProductInWishlist = selectWishlistProductIds(getState()).includes(productId);

    const apiPath = `${userWishListApiUrlCreator()}${productId}/`;
    const method = { method: isProductInWishlist ? REQUEST_METHODS.DELETE : REQUEST_METHODS.PUT };
    const response = await request(apiPath, method);

    return response;
  },
);

export const wishlistSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(fetchAllWishlist.pending, updateWishlist.pending), state => {
      state.isLoading = true;
    });
    builder.addMatcher(isAnyOf(fetchAllWishlist.fulfilled, updateWishlist.fulfilled), (state, action) => {
      state.isLoading = false;
      state.isWishlistLoaded = true;
      state.data = action.payload;
    });
    builder.addMatcher(isAnyOf(fetchAllWishlist.rejected, updateWishlist.rejected), state => {
      state.isLoading = false;
      state.isWishlistLoaded = true;
    });
  },
  initialState: initialWishlistState,
  name: 'wishlist',
  reducers: {},
});

export default wishlistSlice.reducer;
