import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const LearnMoreSection = styled(Flexbox)`
  padding: 32px 16px;
`;

export const ListWrapper = styled(Flexbox)`
  padding: 16px 0;
`;

export const ListItem = styled(Flexbox)`
  padding: 8px 0;
`;
