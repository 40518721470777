import {
  EDUTAINMENT_FORM_LINK_REPLACEMENT,
  EMAIL_HASH_REPLACEMENT,
  EXPERIENCE_TYPE_REPLACEMENT,
  LEGAL_TAB_SLUG_REPLACEMENT,
  PRODUCT_CATEGORY_REPLACEMENT,
  PROFILE_TAB_SLUG_REPLACEMENT,
  TOKEN_REPLACEMENT,
  UID_REPLACEMENT,
} from '@lib/core/service/consts';

export const PAGES = {
  error: { badRequest: '/error/', forbidden: '/access_denied/', notFound: '/not_found/' },
  ta: {
    landing: '/guided_trial/',
    landingRemote: '/remotegt/',
    legacy: {
      quiz: {
        remote: '/taste_advisor_remote_gt/',
        taste: '/taste_advisor_test/',
        tasteEcommerce: '/taste_advisor_ecommerce_test/',
      },
    },
    linkGenerator: '/link-generator/',
    openIntegration: '/open-integration/',
    products: {
      bestMatch: '/products/best_match/',
      bundles: '/products/bundles/',
    },
    quiz: {
      crafted: '/quiz/crafted/',
      ecommerce: '/quiz/taste-ecommerce/',
      local: '/quiz/taste-local/',
      remote: '/quiz/taste-remote/',
    },
    result: '/result/',
  },
  vinhood: {
    about: '/about/',
    ageAndGender: '/age-and-gender/',
    article: '/article/',
    articles: '/articles/',
    catalog: '/catalog/',
    confirmPasswordReset: `/reset-password/${UID_REPLACEMENT}/${TOKEN_REPLACEMENT}/`,
    eanError: '/ean_error/',
    edutainment: '/edutainment/',
    edutainmentForm: `/edutainment-form/${PRODUCT_CATEGORY_REPLACEMENT}/${EDUTAINMENT_FORM_LINK_REPLACEMENT}`,
    experienceSearch: `/experiences/${EXPERIENCE_TYPE_REPLACEMENT}`,
    experiences: '/experiences/',
    explore: '/explore/',
    exploreList: '/explore-list/',
    globalSearchResults: '/search-results/',
    hint: '/hint/',
    home: '/home/',
    kioskForceLogout: '/kiosk-force-logout/',
    kioskLogin: '/login/',
    // ? nested routewelcome: '/welcome/',
    legal: {
      cookies: '/cookie-policy/',
      privacy: '/privacy-policy/',
      tabs: `/legal/tab/${LEGAL_TAB_SLUG_REPLACEMENT}/`,
      termsAndConditions: '/terms-and-conditions/',
    },

    onboarding: '/onboarding/',

    product: `/product/`,

    profile: `/profile/${PROFILE_TAB_SLUG_REPLACEMENT}/`,

    quiz: {
      chooseExpert: '/quiz/choose/expert/',
      chooseTaste: '/quiz/choose/taste/',
      expert: '/quiz/expert/',
      multiProduct: '/quiz/multi-product/',
      recipe: '/quiz/recipe/',
      situational: '/quiz/situational/',
      taste: '/quiz/taste/',
    },

    quizResults: {
      result: '/result/',
      situational: '/situational_result/',
    },

    recipeSearch: '/recipe-search/',

    redirect: '/redirect/',

    registration: '/email-registration/',

    resetPassword: '/reset/',

    setPassword: `/email-registration/${EMAIL_HASH_REPLACEMENT}/`,

    signIn: '/sign-in/',

    start: '/',
    tasteId: {
      tasteId: '/taste-id/',
      tasteIdLegal: '/taste-id/legal/',
      tasteIdPlacesJournal: '/taste-id/places-journal/',
      tasteIdProductsJournal: '/taste-id/products-journal/',
      tasteIdSettings: '/taste-id/settings/',
      tasteIdTags: '/taste-id/tags/',
    },
    tastePathStartPart: '/taste-path-start/',
    welcome: '/welcome/',
  },
};
