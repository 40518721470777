import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const ChefRecommendsCardContainer = styled.div`
  width: 312px;
  height: 100%;
  overflow: hidden;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};
`;

export const ContentContainer = styled.div`
  padding: 16px 16px 20px;
`;
