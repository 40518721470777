import { useSelector } from 'react-redux';

import {
  selectIsLoadingGlobalSearch,
  selectSearchQuery,
  selectSearchResultsProducts,
  selectSuggestionProductsCount,
} from '@lib/tools/globalSearch/selectors/index';

export const useGlobalSearch = () => ({
  isLoadingGlobalSearch: useSelector(selectIsLoadingGlobalSearch),
  products: useSelector(selectSearchResultsProducts),
  searchQuery: useSelector(selectSearchQuery),
  suggestionProductsCount: useSelector(selectSuggestionProductsCount),
});
