import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const TestResultPageContainer = styled(Flexbox)`
  width: 100%;

  & > div:nth-of-type(2) {
    margin-top: -90px;
    z-index: 2;
  }
`;

export const BannerWrapper = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
`;

export const SkeletonWrapper = styled.div`
  padding: 16px;
`;
