import { FC } from 'react';

import CharacterMapScrollerContainer from '@app/web/src/containers/CharacterMapScrollerContainer';

import { TCharacter } from '@lib/core/characters/types';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { parseCharacterInfo } from '@lib/core/characters/utils/parseCharacterInfo';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import SectionHeader from '@components/web/src/components/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import HowToTaste from '@components/web/src/organisms/Cards/CharacterInfo/HowToTaste/HowToTaste';
import EssenceCard from '@components/web/src/organisms/Cards/EssenceCard/EssenceCard';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  character: TCharacter;
  hideModal: () => void;
  isModalOpen: boolean;
  locale: string;
}

const CharacterModal: FC<IProps> = ({ character, hideModal, isModalOpen, locale }) => {
  const { productCategoriesPlural } = localeCommon;
  const { characterModal } = localeApp;

  const {
    sortedRelatedCharacterEssences = [],
    characterCaption,
    characterDescription,
    technicalCaption,
    technicalDescription,
    characterName,
    characterImage,
    characterProductCategory,
  } = parseCharacterDetails({ character });

  const { publishedTerms } = LocaleUtils;

  const characterInfoData = parseCharacterInfo({
    characterData: character,
    locale,
    productCategory: characterProductCategory,
  });

  const productCategoryPluralText = publishedTerms[productCategoriesPlural[characterProductCategory]?.id];
  const sectionDescription = publishedTerms[characterModal.sectionDescription.id];

  return (
    <ModalWrapper fullWidthModal hideModal={hideModal} isOpen={isModalOpen} modalClassName="custom-character-wrapper">
      <div className="character-modal-container">
        <div className="character-upper-content">
          <div className={`character-image-wrapper ${characterProductCategory}`}>
            <div className="image-container" style={{ backgroundImage: `url(${characterImage})` }} />
          </div>
          <p className={`character-name ${characterProductCategory}`}>#{characterName}</p>
          <p className="character-caption">{characterCaption}</p>
          <p className="character-description">{characterDescription}</p>
          <p className="character-technical-caption">{technicalCaption}</p>
          <p className="character-technical-description">{technicalDescription}</p>
        </div>
        <div className="character-essence-container">
          <SectionHeader
            sectionDescription={characterModal.essenceSectionDesc}
            sectionTitle={characterModal.essenceTitle}
            descriptionLocaleOptions={{
              productCategory: characterProductCategory,
              productCategoryPluralText,
              sectionDescription,
            }}
          />
          <SwiperHOC>
            {sortedRelatedCharacterEssences?.map(essence => (
              <EssenceCard key={essence.identifier} {...essence} productCategory={characterProductCategory} />
            ))}
          </SwiperHOC>
        </div>
        <div className="character-taste-container">
          <SectionHeader
            sectionDescription={characterModal.characterTasteDesc}
            sectionTitle={characterModal.tasteTitle}
            descriptionLocaleOptions={{
              productCategory: characterProductCategory,
              productCategoryPluralText,
              sectionDescription,
            }}
          />
          <SwiperHOC>{characterInfoData?.map((taste, idx) => <HowToTaste key={idx} {...taste} />)}</SwiperHOC>
        </div>
        <CharacterMapScrollerContainer characterName={characterName} productCategory={characterProductCategory} />
      </div>
    </ModalWrapper>
  );
};

export default CharacterModal;
