import React from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import * as S from '@components/web/src/components/Authentication/RegistrationBanner/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  handleNavigateToRegistrationPage: () => void;
}

const RegistrationBanner: React.FC<IProps> = ({ handleNavigateToRegistrationPage }) => {
  const { registrationBannerTitle, registrationBannerBtn } = localeWidget.registrationBanner;

  return (
    <S.RegistrationBanner data-testid="RegistrationBanner">
      <Text color={STATIC_COLORS.base.black} size="body1" text={registrationBannerTitle} weight="medium" />
      <Button
        fontSize="subtitle2"
        handleClick={handleNavigateToRegistrationPage}
        size="md"
        text={registrationBannerBtn}
        variant={VH_VARIANTS.PRIMARY}
      />
    </S.RegistrationBanner>
  );
};

export default RegistrationBanner;
