import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const HintItem = styled.div`
  width: 100%;
  padding: 8px;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;

  strong {
    font-weight: 600;
  }
`;

export const StyledTextSubtitle = styled(Text)`
  padding-block: 24px 16px;
`;

export const ContentSection = styled(Flexbox)`
  padding: 16px;
  margin-top: -85px;
  position: relative;
  z-index: 2;
`;

export const TastePathStartPageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary-100);
`;

export const HeaderWrapper = styled(Flexbox)`
  padding-inline: 16px;
  background-color: ${STATIC_COLORS.teal[700]};
  height: 120px !important;
`;

export const HeaderImageWrapper = styled.div`
  position: relative;
  width: 120px !important;
  height: 120px !important;
  flex-shrink: 0;

  .mask-container {
    position: absolute;
  }

  img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
