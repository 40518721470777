import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const Icon = styled(Flexbox)`
  width: 100%;
  height: 48px;
`;

export const ThirdPartyConnectModalWrapper = styled(Flexbox)`
  padding: 0px 16px 24px;
`;
