import { css, styled } from 'styled-components';

import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';

export const ShowOnlyContainer = styled.button<{ $isActive?: boolean }>`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: ${STATIC_COLORS.warmGray['100']};
  border-bottom: 1px solid ${DEFAULT_COLOR_THEME.secondaryColor['-300']};
`;

export const IconWrapper = styled.div<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${STATIC_COLORS.base.white};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${STATIC_COLORS.teal['300']};
    `}
`;
