import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const IdentityCardContainer = styled(Flexbox)`
  border-top: 1px solid ${STATIC_COLORS.warmGray[600]};
`;

export const Item = styled.div`
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid ${STATIC_COLORS.warmGray[600]};
`;

export const WarningText = styled(Text)`
  display: block;
  margin-top: 6px;
`;
