import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useApp } from '@lib/core/service/hooks';
import { setShowTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { selectIsFeedbackLoading } from '@lib/core/users/selectors/feedback';
import { PAGES } from '@lib/tools/views/urls';

import TastePathStartPage from '@components/web/src/pages/TastePathStartPage/TastePathStartPage';

const TastePathStartPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFeedbackLoading = useSelector(selectIsFeedbackLoading);
  const { userCharacters } = useUser();
  const characterName = userCharacters.find(character => isCharacterByServiceProductCategory(character)).name || '';

  const { shouldShowTastePathStartPage } = useApp();

  const navigateToCatalog = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const onHandleClick = () => dispatch(setShowTastePathStartPage(false));

  useEffect(() => {
    if (!shouldShowTastePathStartPage) {
      navigateToCatalog();
    }
  }, [shouldShowTastePathStartPage]);

  return (
    <TastePathStartPage characterName={characterName} isDisabledBtn={isFeedbackLoading} onHandleClick={onHandleClick} />
  );
};

export default TastePathStartPageContainer;
