import { css, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const TastePathProductItem = styled.div<{ $isUpdatedItem: boolean }>`
  display: flex;
  gap: 32px;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};
  cursor: pointer;

  button {
    align-self: center;
  }

  ${({ $isUpdatedItem }) =>
    $isUpdatedItem &&
    css`
      background-image: linear-gradient(to right, ${STATIC_COLORS.green[200]} 50%, ${STATIC_COLORS.base.white} 50%);
      background-repeat: no-repeat;
      background-position: 130% 0;
      background-size: 20% 220%;
      transition: background-position 1s;
    `}
`;
