/* eslint-disable sort-keys-fix/sort-keys-fix */
import { TProductInstance } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

type IPageIdentitiesProps = {
  productInstanceData: TProductInstance;
  locale: string;
};

export interface IIdentity {
  title: ILocaleText;
  description: ILocaleText | string;
}

export interface IIdentityCardProps {
  identityWarningText: ILocaleText;
  productIdentities: IIdentity[];
}

export const parseProductIdentities = ({ productInstanceData, locale }: IPageIdentitiesProps) => {
  const {
    character,
    character: {
      attributes: {
        ideal_temperature_min_celsius: minCelsiusTemperature,
        ideal_temperature_max_celsius: maxCelsiusTemperature,
      },
    } = {},
    product: { region, category, attributes, attributes: { alcohol } } = {},
  } = productInstanceData;

  const {
    productIdentitiesCard: {
      warningText: identityWarningText,
      notPresentText,
      typeTitleText,
      originTitleText,
      grapeTitleText,
      colorTitleText,
      alcoholTitleText,
      aromasTitleText,
      tasteTitleText,
      glassTitleText,
      temperatureTitleText,
      allergensTitleText,
      fermentationTitleText,
      specialIngredientTitleText,
      varietyTitleText,
      roastedTitleText,
    },
  } = localeCommon;

  const productRegion = (region && region[0]?.name) || '';
  const productCountry = (region && region[0]?.country) || '';

  const identityCardProps: IIdentityCardProps = {
    productIdentities: [
      { title: typeTitleText, description: attributes[`type_${locale}`] },
      { title: originTitleText, description: `${productRegion}, ${productCountry}` },
      { title: colorTitleText, description: attributes[`color_${locale}`] },
      { title: aromasTitleText, description: attributes[`aromas_${locale}`] || attributes[`aroma_${locale}`] },
      { title: tasteTitleText, description: attributes[`taste_${locale}`] },
    ],
    identityWarningText,
  };

  if (category === PRODUCT_CATEGORY_WINE) {
    identityCardProps.productIdentities.push(
      { title: grapeTitleText, description: attributes[`variety_${locale}`] },
      { title: alcoholTitleText, description: alcohol ? `${alcohol}%` : '' },
      { title: glassTitleText, description: character.attributes[`ideal_glass_${locale}`] },
      {
        title: temperatureTitleText,
        description: `${minCelsiusTemperature}° - ${maxCelsiusTemperature}°`,
      },
      {
        title: allergensTitleText,
        description: attributes[`allergens_${locale}`] || notPresentText,
      },
    );
  }

  if (category === PRODUCT_CATEGORY_BEER) {
    identityCardProps.productIdentities.push(
      { title: fermentationTitleText, description: attributes[`variety_${locale}`] },
      { title: alcoholTitleText, description: alcohol ? `${alcohol}%` : '' },
      {
        title: specialIngredientTitleText,
        description: character.attributes[`special_ingredient_${locale}`],
      },
      { title: glassTitleText, description: character.attributes[`ideal_glass_${locale}`] },
      {
        title: temperatureTitleText,
        description: `${minCelsiusTemperature}° - ${maxCelsiusTemperature}°`,
      },
      {
        title: allergensTitleText,
        description: attributes[`allergens_${locale}`] || notPresentText,
      },
    );
  }

  if (category === PRODUCT_CATEGORY_COFFEE) {
    identityCardProps.productIdentities.push(
      { title: varietyTitleText, description: attributes[`variety_${locale}`] },
      { title: roastedTitleText, description: attributes[`transformation_location_${locale}`] },
      {
        title: allergensTitleText,
        description: attributes[`allergens_${locale}`] || notPresentText,
      },
    );
  }

  return identityCardProps;
};
