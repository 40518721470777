import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const EssenceContainer = styled(Flexbox)`
  width: 312px;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};
  color: ${STATIC_COLORS.base.black};
`;

export const EssenceDetails = styled(Flexbox)`
  .mp-progress-bar-container {
    width: 100%;
  }
`;
