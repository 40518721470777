import {
  EAN_CODE_REPLACEMENT,
  ITEM_ID_REPLACEMENT,
  PRODUCT_SLUG_REPLACEMENT,
  QUIZ_SESSION_ID_REPLACEMENT,
  RETAILER_LOCATION_SLUG_FOR_REPLACEMENT,
  RETAILER_SLUG_FOR_REPLACEMENT,
  STORE_ID_REPLACEMENT,
} from '@lib/core/service/consts';

/* eslint-disable */
const backendApiUrls = {
  loginUrl: '/users/rest-auth/token/',
  refreshTokenUrl: '/users/rest-auth/token/refresh/',

  // Preferences should be refactored in separate task, since complicated logic is mixed between files
  apiUrlGETUserProductPreferences: `/users/${QUIZ_SESSION_ID_REPLACEMENT}/product-preferences/`,
  apiUrlPUTUserProductPreferences: `/users/${QUIZ_SESSION_ID_REPLACEMENT}/product-preferences/${PRODUCT_SLUG_REPLACEMENT}/`,

  apiUrlRecipeAutoSuggest: `/products/${RETAILER_SLUG_FOR_REPLACEMENT}/recipes-autosuggest/`,
 
  // Get product attributes
  apiUrlGetListFormats: `/product-attributes/formats/${RETAILER_SLUG_FOR_REPLACEMENT}/${RETAILER_LOCATION_SLUG_FOR_REPLACEMENT}/coffee/`,

  // EAN based search
  apiUrlGetProductBasedOnEan: `/products/${RETAILER_SLUG_FOR_REPLACEMENT}/${RETAILER_LOCATION_SLUG_FOR_REPLACEMENT}/ean-search/?ean_code=${EAN_CODE_REPLACEMENT}`,

  apiUrlSendDynamoRedirect: '/dynamodb/redirect/',

  webSocketApis: {
    remoteAuthUrl: '/ws/remote-kiosk-auth/',
  },

  // Vusion API
  apiUrlPostFlashLabel: `/products/${RETAILER_SLUG_FOR_REPLACEMENT}/ex-call/vusion/store/${STORE_ID_REPLACEMENT}/item/${ITEM_ID_REPLACEMENT}/flash-labels/`,

  // B2C
  apiUrlBanners: `/banners/${RETAILER_SLUG_FOR_REPLACEMENT}/${RETAILER_LOCATION_SLUG_FOR_REPLACEMENT}/?limit=1000`,

  apiUrlAllUserProductPreferences: '/products/user-product-preferences/',

  apiUrlB2CExperiencesLists: `/experiences/`,
 
  // Mocked
  apiUrlArticlesList: '/static/mocks/articles.json',
};

export default backendApiUrls;
