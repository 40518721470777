import { store } from '@lib/core/service/store';
import { prependBasename } from '@lib/core/service/utils';
import { selectIsUserHasAnyCharacter, selectUserRole } from '@lib/core/users/selectors/user';
import { PAGES } from '@lib/tools/views/urls';

export const getHeaderAppLogoUrl = () => {
  const state = store.getState();
  const userRole = selectUserRole(state);
  const isUserHasAnyCharacter = selectIsUserHasAnyCharacter(state);

  let redirectUrl = prependBasename(PAGES.vinhood.home);
  if (!userRole) {
    if (isUserHasAnyCharacter) {
      redirectUrl = prependBasename(PAGES.vinhood.quizResults.result);
    } else {
      redirectUrl = prependBasename(PAGES.vinhood.start);
    }
  } else if (!isUserHasAnyCharacter) {
    redirectUrl = prependBasename(PAGES.vinhood.quiz.chooseTaste);
  }
  return redirectUrl;
};
