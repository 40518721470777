import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
} from '@lib/core/service/slices/technical/registerPageScenario';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import discoverIcon from '@components/web/src/assets/legacy/icons/test_result_discover_icon.svg';
import diveIcon from '@components/web/src/assets/legacy/icons/test_result_dive_icon.svg';
import honeIcon from '@components/web/src/assets/legacy/icons/test_result_hone_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TSwiperData } from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import * as S from '@components/web/src/organisms/TestResult/LearnMoreSection/styles';

interface IProps {
  characterData: TCharacter;
  productCategory: TProductCategory;
  charactersModalData: TSwiperData[];
  handleLearnMoreSectionClick?: (payload: TRegisterPageScenarioState) => void;
}

const LearnMoreSection: FC<IProps> = ({
  characterData,
  productCategory,
  charactersModalData,
  handleLearnMoreSectionClick,
}) => {
  const characterName = characterData?.name || '';
  const characterId = characterData?.identifier || '';
  const characterCaption = characterData?.character_caption?.replace(/ and /g, ' & ');

  const { productCategoriesPlural } = localeCommon;
  const { titleText, btnText, likeProText, discoverText, honeText, diveText } = localeApp.testResultPage;

  const features = [
    { icon: discoverIcon, title: discoverText },
    { icon: honeIcon, title: honeText },
    { icon: diveIcon, title: diveText },
  ];

  return (
    <S.LearnMoreSection data-testid={`LearnMoreSection-${productCategory}`} direction="column" gap={32}>
      <Flexbox align="center" direction="column">
        <Text
          color={STATIC_COLORS.productColors[productCategory]?.[500]}
          fontFamily="Fraunces"
          localeIndex={{ productCategory }}
          localeOptions={{ characterName }}
          localeVariables={{ productCategoryPluralText: productCategoriesPlural[productCategory] }}
          size="h5"
          text={titleText}
          weight="semibold"
        />
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h5"
          text={`${characterCaption} 💥`}
          weight="semibold"
        />
      </Flexbox>
      <Button
        fontSize="subtitle2"
        size="lg"
        text={btnText}
        variant={VH_VARIANTS.LIGHT}
        handleClick={() => {
          MixpanelTracker.events.characterDescriptionClick({ productCharacterID: characterId });

          handleLearnMoreSectionClick?.({
            productCategory,
            scenario: RegisterPageScenarios.CharacterClick,
            swiperData: charactersModalData,
          });
        }}
      />
      <S.ListWrapper direction="column" gap={6}>
        <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={likeProText} weight="semibold" />
        {features?.map((item, idx) => (
          <S.ListItem key={idx} align="center" gap={16}>
            <img alt="" src={item.icon} />
            <Text color={STATIC_COLORS.base.black} size="body1" text={item.title} weight="semibold" />
          </S.ListItem>
        ))}
      </S.ListWrapper>
    </S.LearnMoreSection>
  );
};

export default LearnMoreSection;
