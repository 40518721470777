import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import HeaderImage from '@components/web/src/assets/legacy/b2b/widget/taste_test_expert_sketch.png';
import { ReactComponent as ImageBGMask } from '@components/web/src/assets/legacy/b2b/widget/taste_test_vector_mask.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import TastePathGuide from '@components/web/src/organisms/TastePathGuide/TastePathGuide';
import * as S from '@components/web/src/pages/TastePathStartPage/styles';

interface IProps {
  isDisabledBtn: boolean;
  onHandleClick: () => void;
  characterName: string;
}

const TastePathStartPage: FC<IProps> = ({ characterName, onHandleClick, isDisabledBtn }) => {
  const { tastePathStartPage } = localeCommon;

  return (
    <S.TastePathStartPageContainer data-testid="TastePathStartPageContainer">
      <S.HeaderWrapper isFullWidth align="center" gap={0} justify="space-between">
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          size="h5"
          text={tastePathStartPage.title}
          weight="semibold"
        />
        <S.HeaderImageWrapper>
          <span className="mask-container">
            <ImageBGMask />
          </span>
          <img alt="taste_test" src={HeaderImage} />
        </S.HeaderImageWrapper>
      </S.HeaderWrapper>
      <TastePathGuide characterName={characterName} isDisabledBtn={isDisabledBtn} onHandleClick={onHandleClick} />
    </S.TastePathStartPageContainer>
  );
};

export default TastePathStartPage;
