import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import {
  RegisterPageScenarios,
  TRegisterPageScenarioState,
} from '@lib/core/service/slices/technical/registerPageScenario';

import { FooterAppAnonymous } from '@components/web/src/atoms/Footers';
import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import ProductCardSkeleton from '@components/web/src/components/Skeleton/ProductCardSkeleton/ProductCardSkeleton';
import CharacterAdjectivesGroup, {
  ICharacterAdjectiveData,
} from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import { TSwiperData } from '@components/web/src/organisms/ExtraInfoSwiper/ExtraInfoSwiper';
import TastePathGuide from '@components/web/src/organisms/TastePathGuide/TastePathGuide';
import LearnMoreSection from '@components/web/src/organisms/TestResult/LearnMoreSection/LearnMoreSection';
import ProductSection from '@components/web/src/organisms/TestResult/ProductSection/ProductSection';
import QuizSection, { IQuizSectionQuizData } from '@components/web/src/organisms/TestResult/QuizSection/QuizSection';
import * as S from '@components/web/src/templates/ResultPages/TestResultPage/styles';

interface IProps {
  locale: string;
  storeType: TRetailerLocationStoreType;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  characterData: TCharacter;
  quizData: IQuizSectionQuizData[];
  charactersModalData: TSwiperData[];
  characterAdjectiveData: ICharacterAdjectiveData;
  retailerLocationLogo?: string;
  isDesignSetVinhoodExperience?: boolean;
  isLoadingProduct?: boolean;
  isEnableTastePathAddon?: boolean;
  handleUpdateWishlistProductList?: (productId: string) => void;
  handleAuthFeatureClick?: (payload?: TRegisterPageScenarioState) => void;
  handleProductCardClick?: () => void;
}

const TestResultPage: FC<IProps> = ({
  locale,
  storeType,
  productInstanceData,
  characterData,
  quizData,
  charactersModalData,
  productCategory,
  characterAdjectiveData,
  retailerLocationLogo,
  isDesignSetVinhoodExperience = false,
  isLoadingProduct = false,
  isEnableTastePathAddon = false,
  handleAuthFeatureClick,
  handleUpdateWishlistProductList,
  handleProductCardClick,
}) => {
  return (
    <S.TestResultPageContainer data-testid={`TestResultPage-${productCategory}-${storeType}`} direction="column">
      <CharacterAdjectivesGroup
        isTestResultPage
        characterAdjectiveData={characterAdjectiveData}
        characterData={characterData}
        handleAdjectiveClick={handleAuthFeatureClick}
        productCategory={productCategory}
      />
      {isEnableTastePathAddon ? (
        <TastePathGuide isResultPage characterName={characterData.name} />
      ) : (
        <>
          {isLoadingProduct && (
            <S.SkeletonWrapper>
              <ProductCardSkeleton />
            </S.SkeletonWrapper>
          )}
          {!!productInstanceData && (
            <ProductSection
              handleAuthFeatureClick={handleAuthFeatureClick}
              handleProductCardClick={handleProductCardClick}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              locale={locale}
              productCategory={productCategory}
              productInstanceData={productInstanceData}
              retailerLocationLogo={retailerLocationLogo}
              storeType={storeType}
            />
          )}
          <QuizSection
            characterData={characterData}
            handleQuizSectionClick={payload => handleAuthFeatureClick({ ...payload, productCategory })}
            quizData={quizData}
          />
          <LearnMoreSection
            characterData={characterData}
            charactersModalData={charactersModalData}
            handleLearnMoreSectionClick={handleAuthFeatureClick}
            productCategory={productCategory}
          />
        </>
      )}

      {!isDesignSetVinhoodExperience && <FooterAppAnonymous shouldOpenModalsUnderHeader showGearIcon />}
      <S.BannerWrapper>
        <RegistrationBanner
          handleNavigateToRegistrationPage={() =>
            handleAuthFeatureClick({ scenario: RegisterPageScenarios.BannerClick })
          }
        />
      </S.BannerWrapper>
    </S.TestResultPageContainer>
  );
};

export default TestResultPage;
