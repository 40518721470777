import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { BANNER_POSITION_CATALOG } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { IBannerDirectory } from '@lib/core/banners/types';
import { useCharacters } from '@lib/core/characters/hooks';
import { charactersByProductCategory } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { TProductCategory } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  B2C_GET_PRODUCTS_REQUEST_LIMIT,
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  FORMAT_QUERY,
  GPRL_CHARACTER_QUERY,
  IS_FROM_PRODUCT_DETAILS_PAGE,
  ORIGINS_QUERY,
  PREFERENCES_QUERY,
  PRODUCTS_MAX_PRICE_QUERY,
  PRODUCTS_MIN_PRICE_QUERY,
  PRODUCT_CHARACTERISTICS_QUERY,
  PRODUCT_NAME_QUERY,
  PRODUCT_PREFERENCES_URL_PARAM,
  PRODUCT_REQUEST_FOR_HOME_PAGE,
  SEARCH_BY_NAME_FIELDS_QUERY,
  STYLE_QUERY,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useProductPreferences } from '@lib/core/service/hooks/useProductPreferences';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import {
  TProductCatalogOrdering,
  actionApplyCatalogOrdering,
  actionResetCatalogFilters,
} from '@lib/tools/filterManager/slices/productFilter';
import { updateCharactersToggleActive } from '@lib/tools/filterManager/slices/toggleFilterSlice';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import { fetchProductsListsExtended, resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import { useProductsSwiper } from '@lib/tools/productsSwiper/hooks/useProductsSwiper';
import { fetchSwiperProducts } from '@lib/tools/productsSwiper/slices/index';
import { removeUrlParams, setUrlParams } from '@lib/tools/shared/helpers';
import {
  CHARACTERS_URL_PARAM,
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_ORDERING,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE_MAX,
  FILTER_TYPE_PRICE_RANGE_MIN,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  ORDERING_TYPE_RANK,
  OS_PRODUCT_NAME_QUERY,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_URL_PARAM,
  PRODUCT_CATEGORY_WINE,
  PRODUCT_IDENTIFIER_QUERY,
  START_FROM_FULL_CATALOG,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import ProductCatalogPage from '@components/web/src/pages/app/ProductCatalog/ProductCatalogPage/ProductCatalogPage';

const ProductCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isUserAuthenticated, userCharacters } = useUser();
  const userCharactersIndexedByProductCategory = charactersByProductCategory(userCharacters);

  const { locale } = useApp();
  const { retailerStoreType } = useRetailerLocation();

  const productCategoryUrlParam: any = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);
  const disableUserCharacterToggleUrlParam = !!searchParams.get(DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM);
  const charactersStringUrlParam = searchParams.get(CHARACTERS_URL_PARAM) || '';
  const preferencesUrlParam = searchParams.get(PRODUCT_PREFERENCES_URL_PARAM);
  const isFromProductDetailsPage = searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE);
  const startFromFullCatalogUrlParam = !!searchParams.get(START_FROM_FULL_CATALOG);

  const [productCategory, setProductCategory] = useState<TProductCategory>(
    productCategoryUrlParam || PRODUCT_CATEGORY_WINE,
  );
  const [showFullCatalog, setShowFullCatalog] = useState(startFromFullCatalogUrlParam);

  const { isProductsRequestLoading, products, itemsCount } = useProductCatalog();

  const {
    toggle: { isCharacterToggleActive = false },
    filterManager: { isFilterOpened },
  } = useTypedSelector(state => state.productFilters);

  const { productsData } = useProductsSwiper();

  const isCharacterExistsForProductCategory = !!userCharactersIndexedByProductCategory[productCategory];

  const { technical_caption: characterCaption = '' } = userCharactersIndexedByProductCategory[productCategory] || {};

  const { userProductsPreferencesString, isUserProductPreferencesDataLoading } = useProductPreferences();
  const currentTabBanners = useBanners({
    bannerPosition: `${BANNER_POSITION_CATALOG}/${productCategory}` as IBannerDirectory,
    returnAllBanners: true,
  });

  // ToDo check getBanner
  const getBanner: () => TBannerCard = () => getMultipleUniqueRandomItemsFromArray(currentTabBanners, 1)[0];

  const {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsQuery,
    [FILTER_TYPE_FORMAT]: selectedFormatsQuery,
    [FILTER_TYPE_ORIGIN]: selectedOriginsQuery,
    [FILTER_TYPE_STYLE]: selectedStylesQuery,
    [FILTER_TYPE_PRICE_RANGE_MAX]: priceRangeMaxQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextValue,
    [FILTER_TYPE_PRICE_RANGE_MIN]: priceRangeMinQuery,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistProductsQuery,
    [FILTER_TYPE_CHARACTER_TOGGLE]: isUseMyCharacterToggleEnabled,
    [FILTER_TYPE_ORDERING]: activeOrdering,
    [FILTER_TYPE_RATING]: selectedRatedProductsQuery,
  } = useCatalogFilter();

  const userCharactersIDsStringFromStore = useMemo(() => {
    if (Object.keys(userCharactersIndexedByProductCategory).length) {
      return userCharactersIndexedByProductCategory[productCategory]?.identifier;
    }
    return '';
  }, [userCharactersIndexedByProductCategory, productCategory]);

  const characterQuery = isUseMyCharacterToggleEnabled ? userCharactersIDsStringFromStore : charactersStringUrlParam;

  const { feedbackData, handleUpdateFeedback } = useFeedback();
  // TODO: replace useWishlist to useProductList after full start using useProductList
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useWishlist();

  const { discoveryQuiz } = useDiscoveryQuizData();

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory, isCharacterToggleActive);
    if (!isFromProductDetailsPage) {
      dispatch(actionResetCatalogFilters());
      dispatch(resetProductCatalogState());
    }

    if ((disableUserCharacterToggleUrlParam || charactersStringUrlParam) && !isFromProductDetailsPage) {
      dispatch(updateCharactersToggleActive({ value: false }));
    }
    if (isFromProductDetailsPage) {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE],
      });
    }
    if (disableUserCharacterToggleUrlParam) {
      setShowFullCatalog(true);
    }
  }, []);

  const handleProductsRequest = ({
    isPagination = false,
    isForCollectFilters = false,
  }: IHandleProductsRequestParams) => {
    const paginationParams = {
      limit: isForCollectFilters ? 0 : B2C_GET_PRODUCTS_REQUEST_LIMIT,
      offset: isPagination ? products.length : 0,
    };

    const filterParams = {};
    filterParams[PRODUCT_CATEGORY_QUERY] = productCategory;

    if (userProductsPreferencesString && !disableUserCharacterToggleUrlParam) {
      filterParams[PREFERENCES_QUERY] = userProductsPreferencesString;
    }

    if (priceRangeMaxQuery && !isForCollectFilters) {
      filterParams[PRODUCTS_MAX_PRICE_QUERY] = priceRangeMaxQuery;
      filterParams[PRODUCTS_MIN_PRICE_QUERY] = priceRangeMinQuery;
    }

    if (searchTextValue) {
      filterParams[PRODUCT_NAME_QUERY] = `*${searchTextValue}*`;
      filterParams[SEARCH_BY_NAME_FIELDS_QUERY] = OS_PRODUCT_NAME_QUERY;
    }

    if (characterQuery && !isForCollectFilters) {
      filterParams[GPRL_CHARACTER_QUERY] = characterQuery;
    }

    if (preferencesUrlParam) {
      filterParams[PREFERENCES_QUERY] = filterParams[PREFERENCES_QUERY]
        ? `${filterParams[PREFERENCES_QUERY]},${preferencesUrlParam}`
        : preferencesUrlParam;
    }

    if (selectedOriginsQuery) {
      filterParams[ORIGINS_QUERY] = selectedOriginsQuery;
    }

    if (selectedFormatsQuery) {
      filterParams[FORMAT_QUERY] = selectedFormatsQuery;
    }

    if (selectedStylesQuery) {
      filterParams[STYLE_QUERY] = selectedStylesQuery;
    }

    if (selectedCharacteristicsQuery) {
      filterParams[PRODUCT_CHARACTERISTICS_QUERY] = selectedCharacteristicsQuery;
    }

    if (selectedWishlistProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedWishlistProductsQuery;
    }

    if (selectedRatedProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedRatedProductsQuery;
    }

    if (selectedWishlistProductsQuery && selectedRatedProductsQuery) {
      /** Both filters use the same PRODUCT_IDENTIFIER_QUERY parameter.
       * If both filters are applied we need to find common Ids between them .
       */

      const findCommonProductIds = (wishlist, feedback) =>
        wishlist
          .split('%')
          .filter(item => feedback.includes(item))
          .map(identifier => `%${identifier}`)
          .join('');

      filterParams[PRODUCT_IDENTIFIER_QUERY] = findCommonProductIds(
        selectedWishlistProductsQuery,
        selectedRatedProductsQuery,
      );
    }

    if (activeOrdering) {
      filterParams[FILTER_TYPE_ORDERING] = activeOrdering;
    }

    return dispatch(
      fetchProductsListsExtended({
        isForCollectFilters,
        isPagination,
        params: {
          ...paginationParams,
          ...filterParams,
        },
      }),
    );
  };

  const handleCancelCharacter = characterID => {
    if (userCharactersIDsStringFromStore.includes(characterID)) {
      dispatch(updateCharactersToggleActive({ value: false }));
    }
    const newCharactersArray = characterQuery
      .split('-')
      .filter(currentCharacterID => currentCharacterID !== characterID)
      .join('-');
    setUrlParams([{ key: CHARACTERS_URL_PARAM, value: newCharactersArray }]);
  };

  useEffect(() => {
    userCharacters.forEach(character => {
      if (character && !isUserProductPreferencesDataLoading) {
        const requestParams = {
          [FILTER_TYPE_ORDERING]: ORDERING_TYPE_RANK,
          [GPRL_CHARACTER_QUERY]: character.identifier,
          [PRODUCT_CATEGORY_QUERY]: character.product_category,
          offset: 0,
        };

        if (userProductsPreferencesString) {
          requestParams[PREFERENCES_QUERY] = userProductsPreferencesString;
        }
        dispatch(fetchSwiperProducts({ params: requestParams, productResultFor: PRODUCT_REQUEST_FOR_HOME_PAGE }));
      }
    });
  }, [userCharacters, userProductsPreferencesString]);

  const handleSetProductCategoryTab = (selectedProductCode: TProductCategory) => {
    dispatch(actionResetCatalogFilters());
    resetProductCatalogState();
    setProductCategory(selectedProductCode);
    navigate(prependBasename(PAGES.vinhood.catalog, { productCategory: selectedProductCode }));
    setShowFullCatalog(false);
  };

  const { characters } = useCharacters();
  const translatedUserCharactersData = characters.filter(character =>
    isCharacterByIdentifiers(character, characterQuery?.split('-')),
  );

  const charactersFilterTagsData = useMemo(
    () =>
      translatedUserCharactersData.map(character => ({
        filterType: FILTER_TYPE_CHARACTER_TOGGLE,
        isActive: isUseMyCharacterToggleEnabled,
        name: character?.name,
        value: character?.identifier,
      })),
    [translatedUserCharactersData, isUseMyCharacterToggleEnabled],
  );

  const filterProps = {
    additionalCharacters: charactersFilterTagsData,
    additionalFiltersEnabled: {
      [FILTER_TYPE_CHARACTER_TOGGLE]: true,
      ...(isUserAuthenticated && { [FILTER_TYPE_RATING]: true }),
      [FILTER_TYPE_SHOW_FAVORITES]: !disableUserCharacterToggleUrlParam,
    },
    handleCancelCharacter,
    isSearchEnabled: true,
  };

  const handleGoBackFromSituationalContainer = () => {
    navigate(pathname);
    dispatch(resetProductCatalogState());
    handleSetProductCategoryTab(productCategoryUrlParam || PRODUCT_CATEGORY_WINE);
  };

  const handleBannerClick = (route, linkParams) =>
    navigate(`${prependBasename(`/${route}/`)}${linkParams ? `?${linkParams}` : ''}`);

  const handleShowFullCatalogPage = () => {
    setShowFullCatalog(true);
  };

  const handleBackBtnClick = () => {
    if (showFullCatalog) {
      setShowFullCatalog(false);
    }
  };

  useEffect(() => {
    if (showFullCatalog) {
      setUrlParams([{ key: START_FROM_FULL_CATALOG, value: 'true' }]);
    } else {
      removeUrlParams({ keys: [START_FROM_FULL_CATALOG] });
    }
  }, [showFullCatalog]);

  useEffect(() => {
    const container = document.querySelector('.catalog-page-header');
    if (container) {
      container.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isFilterOpened]);

  const handleChangeOrdering = (newValue: TProductCatalogOrdering) => {
    dispatch(actionApplyCatalogOrdering(newValue));
  };

  const navigateToFoodHabitsPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  return (
    <ProductCatalogPage
      activeOrdering={activeOrdering}
      banners={currentTabBanners}
      characterCaption={characterCaption}
      discoveryQuiz={discoveryQuiz}
      feedbackData={feedbackData}
      filterProps={filterProps}
      getRandomBanner={getBanner}
      handleBackBtnClick={handleBackBtnClick}
      handleBannerClick={handleBannerClick}
      handleChangeOrdering={handleChangeOrdering}
      handleChangeProductCategory={handleSetProductCategoryTab}
      handleGoBackFromSituationalContainer={handleGoBackFromSituationalContainer}
      handleProductsRequest={handleProductsRequest}
      handleShowFullCatalogBannerClick={handleShowFullCatalogPage}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isChangeOrderingEnabled={!!priceRangeMaxQuery}
      isCharacterExistsForProductCategory={isCharacterExistsForProductCategory}
      isFromSituationTest={disableUserCharacterToggleUrlParam}
      isProductListLoading={isProductListLoading}
      itemsCount={itemsCount}
      locale={locale}
      navigateToFoodHabitsPage={navigateToFoodHabitsPage}
      productCategory={productCategory}
      products={products}
      productsList={productsData[productCategory]}
      showFullCatalog={showFullCatalog}
      storeType={retailerStoreType}
      wishlistProductIds={wishlistProductIds}
      isProductsRequestLoading={
        isProductsRequestLoading || (isUserProductPreferencesDataLoading && !!userCharactersIDsStringFromStore)
      }
    />
  );
};

export default ProductCatalogContainer;
