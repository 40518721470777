import { useDispatch, useSelector } from 'react-redux';

import { selectIsThirdPartyConnectModalShown } from '@lib/core/service/selectors';
import { setThirdPartyConnectModalOpenScenario } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import {
  selectFeedbackData,
  selectIsFeedbackLoading,
  selectLastUpdatedFeedbackId,
} from '@lib/core/users/selectors/feedback';
import { TFeedback, actionUpdateFeedback } from '@lib/core/users/slices/feedbacks';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

export const useFeedback = () => {
  const dispatch = useDispatch();
  const { openModal } = useModals();

  const feedbackData = useSelector(selectFeedbackData);
  const isFeedbackLoading = useSelector(selectIsFeedbackLoading);
  const lastUpdatedFeedbackId = useSelector(selectLastUpdatedFeedbackId);
  const isThirdPartyConnectModalShown = useSelector(selectIsThirdPartyConnectModalShown);

  const handleUpdateFeedback = (feedback: TFeedback, productId: string) => {
    if (
      !isThirdPartyConnectModalShown.rate5 &&
      !isThirdPartyConnectModalShown.rate4 &&
      (feedback === 4 || feedback === 5)
    ) {
      dispatch(
        setThirdPartyConnectModalOpenScenario(
          feedback === 4 ? ThirdPartyConnectModalScenario.RATE4 : ThirdPartyConnectModalScenario.RATE5,
        ),
      );
      openModal(MODALS.THIRD_PARTY_CONNECT_MODAL);
    }

    dispatch(actionUpdateFeedback({ feedback, productId }));
  };

  return { feedbackData, handleUpdateFeedback, isFeedbackLoading, lastUpdatedFeedbackId };
};
