import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const StyledTextSubtitle = styled(Text)`
  padding-block: 24px 16px;
`;

export const StyledTextTitle = styled(Text)`
  text-align: center;
`;

export const HintItem = styled.div`
  width: 100%;
  padding: 8px;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;

  strong {
    font-weight: 600;
  }
`;

export const TextWrapper = styled(Flexbox)`
  margin-top: 18px;
`;

export const TastePathGuideContainer = styled(Flexbox)<{ $isResultPage: boolean }>`
  padding-inline: 16px;
  ${({ $isResultPage }) =>
    $isResultPage &&
    css`
      margin-bottom: 32px;
    `}
`;
