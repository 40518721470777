import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const PreparationCardContainer = styled.div`
  width: 164px;
  height: 100%;
  overflow: hidden;
  background-color: ${STATIC_COLORS.base.white};
  color: ${STATIC_COLORS.base.black};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.sm};
`;

export const ContentContainer = styled.div`
  padding: 8px;
  min-height: 100px;
`;
