import { createSelector } from '@reduxjs/toolkit';

import { ISearchInitialStateProps } from '@lib/tools/globalSearch/types/types';

/**
 * Selects the global search state.
 */
export const selectGlobalSearchState = (state): ISearchInitialStateProps => state.globalSearch || {};

/**
 * Selects the loading state for the global search.
 */
export const selectIsLoadingGlobalSearch = createSelector(
  [selectGlobalSearchState],
  globalSearch => globalSearch.isLoadingGlobalSearch,
);

/**
 * Selects the search query string.
 */
export const selectSearchQuery = createSelector(
  [selectGlobalSearchState],
  globalSearch => globalSearch.searchQuery || '',
);

/**
 * Selects the count of suggestion products.
 */
export const selectSuggestionProductsCount = createSelector(
  [selectGlobalSearchState],
  globalSearch => globalSearch.suggestionProductsCount,
);

/**
 * Selects globalSearch slice results.
 */
export const selectSearchResults = createSelector(
  [selectGlobalSearchState],
  globalSearch => globalSearch.searchResults,
);

/**
 * Selects the products from the search results.
 */
export const selectSearchResultsProducts = createSelector(
  [selectSearchResults],
  searchResults => searchResults.products,
);
