import { FC, useMemo } from 'react';

import { IIdentityCardProps } from '@lib/core/products/utils/parseProductIdentities';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/IdentityCard/styles';

const IdentityCard: FC<IIdentityCardProps> = ({ identityWarningText, productIdentities }) => {
  const filteredProductIdentities = useMemo(
    () => productIdentities.filter(details => details.description),
    [productIdentities],
  );

  return (
    <S.IdentityCardContainer isFullWidth align="flex-start" data-testid="IdentityCard" direction="column" gap={0}>
      {filteredProductIdentities?.map(({ title, description }, index) => {
        const lastIndex = filteredProductIdentities.length - 1;
        return (
          <S.Item key={index}>
            <Text color={STATIC_COLORS.gray[600]} size="body2" text={title} />
            {': '}
            <Text color={STATIC_COLORS.base.black} size="body2" text={description as string} weight="medium" />
            {index === lastIndex && (
              <S.WarningText color={STATIC_COLORS.gray[600]} size="body3" text={identityWarningText} />
            )}
          </S.Item>
        );
      })}
    </S.IdentityCardContainer>
  );
};

export default IdentityCard;
