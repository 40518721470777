import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useAuthFeatureClick } from '@app/web/src/hooks/useAuthFeatureClick';

import { isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useThirdPartyNewsletter } from '@lib/core/service/hooks/useThirdPartyNewsletter';
import { shuffleArray } from '@lib/core/service/utils';
import { useFeedback, useUser, useWishlist } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import { fetchProductsListsLimited, resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import { parseLimitedCatalogProduct } from '@lib/tools/shared/helpers';
import { CHARACTERS_URL_PARAM, LIMITED_CATALOG } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import LimitedCatalog from '@components/web/src/pages/widget/Catalog/LimitedCatalog';

const LimitedCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isShowProductLocationAddon,
    isEnableTastePathAddon,
    isEnableAppBanner,
  } = useAddons();

  const characterStringFromUrl = searchParams.get(CHARACTERS_URL_PARAM);

  const { locale, productCategory, shouldHideDownloadAppCard } = useApp();

  const { retailerStoreType, retailerLocationName, retailerLocationSlug } = useRetailerLocation();
  const { userCharacters } = useUser();
  const { handleThirdPartyNewsletter, isShowThirdPartyNewsletter } = useThirdPartyNewsletter();
  const currentUserCharacterId =
    userCharacters.find(character => isCharacterByServiceProductCategory(character))?.identifier || '';

  const characterIdsParam = (characterStringFromUrl || currentUserCharacterId).split('-').join(',');

  const {
    lastDataLoadedTime,
    isProductsRequestLoading,
    firstPriorityProducts,
    secondPriorityProducts,
    thirdPriorityProducts,
  } = useProductCatalog();
  const handleProductsRequest = () => {
    dispatch(fetchProductsListsLimited({ params: { [GPRL_CHARACTER_QUERY]: characterIdsParam } }));
  };

  useEffect(() => {
    return () => {
      if (!window.location.pathname?.includes(PAGES.vinhood.product)) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
      }
    };
  }, []);
  const groupByIdentifier = <T extends { [key: string]: any }>(array: T[]): { [key: string]: T[] }[] => {
    const identifiers: { [key: string]: T[] } = {};

    array.forEach(item => {
      const { identifier } = item.product.character;
      if (identifier) {
        if (!identifiers[identifier]) {
          identifiers[identifier] = [];
        }
        identifiers[identifier].push(item);
      }
    });

    const allProductsArray: T[] = [];
    Object.values(identifiers).forEach(items => {
      allProductsArray.push(...shuffleArray(items));
    });

    return allProductsArray;
  };

  const { matchedProducts, listProducts } = useMemo(() => {
    const shuffledFirstPriorityProducts = shuffleArray(firstPriorityProducts);
    const shuffledSecondPriorityProducts = groupByIdentifier(secondPriorityProducts);
    const shuffledThirdPriorityProducts = groupByIdentifier(thirdPriorityProducts);
    const allProducts = [
      ...shuffledFirstPriorityProducts,
      ...shuffledSecondPriorityProducts,
      ...shuffledThirdPriorityProducts,
    ].map(parseLimitedCatalogProduct);

    const firstPriorityProductsParsed = shuffledFirstPriorityProducts.map(parseLimitedCatalogProduct);

    if (shuffledFirstPriorityProducts.length > 3) {
      return { listProducts: allProducts.slice(3, allProducts.length), matchedProducts: allProducts.slice(0, 3) };
    }
    if (shuffledFirstPriorityProducts.length >= 1 && shuffledFirstPriorityProducts.length < 4) {
      return {
        listProducts: allProducts.slice(shuffledFirstPriorityProducts.length, allProducts.length),
        matchedProducts: firstPriorityProductsParsed,
      };
    }
    return { listProducts: allProducts, matchedProducts: [] };
  }, [firstPriorityProducts, secondPriorityProducts, thirdPriorityProducts]);

  useEffect(() => {
    if (characterIdsParam) handleProductsRequest();
  }, [characterIdsParam]);

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleUpdateFeedback, feedbackData } = useFeedback();
  // TODO: replace useWishlist to useProductList after full start using useProductList
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useWishlist();

  const { discoveryQuiz } = useDiscoveryQuizData();
  const producerName = retailerLocationName || retailerLocationSlug;

  const { handleAuthFeatureClick } = useAuthFeatureClick();

  const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner;

  return (
    <LimitedCatalog
      catalogType={LIMITED_CATALOG}
      discoveryQuiz={discoveryQuiz}
      feedbackData={feedbackData}
      handleAuthFeatureClick={handleAuthFeatureClick}
      handleProductsRequest={handleProductsRequest}
      handleThirdPartyNewsletter={handleThirdPartyNewsletter}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isEnableLocationMapAddon={isEnableLocationMapAddon}
      isEnableTastePathAddon={isEnableTastePathAddon}
      isEnableVusionAddon={isEnableVusionAddon}
      isProductListLoading={isProductListLoading}
      isProductsLoaded={!!lastDataLoadedTime}
      isProductsRequestLoading={isProductsRequestLoading}
      isShowProductLocationAddon={isShowProductLocationAddon}
      isShowThirdPartyNewsletter={isShowThirdPartyNewsletter}
      locale={locale}
      matchedProducts={matchedProducts || []}
      producerName={producerName}
      productCategory={productCategory}
      products={listProducts}
      shouldShowDownloadAppCard={shouldShowDownloadAppCard}
      storeType={retailerStoreType}
      wishlistProductIds={wishlistProductIds}
    />
  );
};

export default LimitedCatalogContainer;
