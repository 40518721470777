import { useDispatch, useSelector } from 'react-redux';

import { selectIsThirdPartyConnectModalShown } from '@lib/core/service/selectors';
import { setThirdPartyConnectModalOpenScenario } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import {
  selectIsWishlistLoaded,
  selectIsWishlistLoading,
  selectWishListData,
  selectWishlistProductIds,
} from '@lib/core/users/selectors/wishlist';
import { updateWishlist } from '@lib/core/users/slices/wishlist';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

export const useWishlist = () => {
  const dispatch = useDispatch();
  const { openModal } = useModals();

  const wishlistProductIds = useSelector(selectWishlistProductIds);
  const wishlistData = useSelector(selectWishListData);
  const isProductListLoading = useSelector(selectIsWishlistLoading);
  const isWishlistLoaded = useSelector(selectIsWishlistLoaded);
  const isThirdPartyConnectModalShown = useSelector(selectIsThirdPartyConnectModalShown);

  const handleUpdateWishlistProductList = productId => {
    if (!wishlistProductIds.length && !isThirdPartyConnectModalShown.wishlist) {
      dispatch(setThirdPartyConnectModalOpenScenario(ThirdPartyConnectModalScenario.WISHLIST));
      openModal(MODALS.THIRD_PARTY_CONNECT_MODAL);
    }

    dispatch(updateWishlist({ productId }));
  };

  return {
    handleUpdateWishlistProductList,
    isProductListLoading,
    isWishlistLoaded,
    wishlistData,
    wishlistProductIds,
  };
};
