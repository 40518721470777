import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { selectRetailerLocation, selectRetailerLocationsList } from '@lib/core/retailers/selectors/retailerLocation';
import { updateRetailerLocationAddons } from '@lib/core/retailers/slices/retailerLocation';
import { ENV_IS_BUILD, isApplicationKiosk, isApplicationPmi } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { setProductCategory, setServiceLocale, toggleMockHost } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import UserUtils from '@lib/core/users/utils/users';
import { Button } from '@lib/tools/devtools/components/inputs/Button';
import { DropdownSelect, MultiSelectChips } from '@lib/tools/devtools/components/inputs/Select';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

export const ServicePanel = () => {
  const { pathname } = window.location;
  const dispatch = useDispatch();

  const { isHostMocked } = useApp();
  const { retailerLanguages } = useRetailer();
  const { retailerLocationId, retailerLocationProductCategories } = useRetailerLocation();
  const { isUserKioskAdmin } = useUser();

  const retailerLocations = useSelector(selectRetailerLocationsList);
  const retailerLocation = useSelector(selectRetailerLocation);
  const { fidelityCardId } = useFidelityCard();

  const removableFidelityCards = ['421034840491', '421034840354'];
  const availableLocations =
    (isUserKioskAdmin && retailerLocation) || !retailerLocations.length ? [retailerLocation] : retailerLocations;

  const SERVICE_SUBDOMAIN = {
    app: 'app',
    kiosk: 'kiosk',
    pmi: 'fa',
  };

  const [appDomain, setAppDomain] = useState(SERVICE_SUBDOMAIN.app);

  const optionsPages = [
    <option key="" disabled>
      select page
    </option>,
  ];

  const { addOns } = useRetailerLocation();
  const { handleResetKioskUserSession } = useKioskRoot();
  const [selectedAddOns, setSelectedAddOn] = useState(addOns);
  const [originalAddOns, setOriginalAddOns] = useState(addOns);

  useEffect(() => {
    if (addOns.length && !originalAddOns.length) {
      setOriginalAddOns(addOns);
    }
  }, [addOns]);

  useEffect(() => {
    dispatch(updateRetailerLocationAddons(selectedAddOns));
  }, [selectedAddOns]);

  const listAddOns = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeAddOns = (name, isChecked) => {
    const currentAddOns = [...selectedAddOns];

    if (isChecked) {
      if (!currentAddOns.includes(name)) {
        setSelectedAddOn([...currentAddOns, name]);
      }
    } else if (currentAddOns.includes(name)) {
      currentAddOns.splice(currentAddOns.indexOf(name), 1);
      setSelectedAddOn(currentAddOns);
    }
  };

  originalAddOns.forEach(addOn => {
    const isChecked = selectedAddOns.includes(addOn);

    listAddOns.push(
      <div key={addOn}>
        <input
          checked={isChecked}
          className="mr-1"
          name={addOn}
          type="checkbox"
          onChange={e => {
            e.stopPropagation();
            handleChangeAddOns(e.target.name, !isChecked);
          }}
        />
        <span className="code">{addOn}</span>
      </div>,
    );
  });

  const handleChangeRetailerLocation = e => {
    const { target: { value: newRetailerLocationId = retailerLocationId } = {} } = e;
    window.location.href = pathname.replace(retailerLocationId, newRetailerLocationId);
  };

  const optionsProducts = [<option key="none">none</option>];

  retailerLocationProductCategories.forEach((product, i) => {
    optionsProducts.push(
      <option key={i} value={product}>
        {product}
      </option>,
    );
  });

  // ! Handle nested routes keys
  // @ts-ignore
  const appPages: any[] = PAGES[isApplicationPmi ? 'ta' : 'vinhood'] || {};
  Object.values(appPages).forEach((page: any, i: number) => {
    if (typeof page !== 'string') {
      // const routeKeys: string[] = Object.keys(page);
      // const routeValues: string[] = Object.values(page);
      // routeKeys.forEach((routeKey, subIndex) => {
      //   optionsPages.push(
      //     <option key={routeKey + subIndex} value={routeValues[subIndex]}>
      //       {routeKeys[subIndex]} - {routeValues[subIndex]}
      //     </option>,
      //   );
      // });
    } else {
      optionsPages.push(
        <option key={i} value={page}>
          {Object.keys(appPages)[i]} - {page}
        </option>,
      );
    }
  });

  const optionsLanguages = [
    <option key="" disabled>
      select locale
    </option>,
  ];

  retailerLanguages.forEach((lang, i) => {
    optionsLanguages.push(
      <option key={i} value={lang.code}>
        {lang.code} - {lang.language}
      </option>,
    );
  });

  const optionsServices = [
    <option key={SERVICE_SUBDOMAIN.app} value={SERVICE_SUBDOMAIN.app}>
      web
    </option>,
    <option key={SERVICE_SUBDOMAIN.kiosk} value={SERVICE_SUBDOMAIN.kiosk}>
      kiosk
    </option>,
    <option key={SERVICE_SUBDOMAIN.pmi} value={SERVICE_SUBDOMAIN.pmi}>
      pmi
    </option>,
  ];

  const optionsLocations = [
    <option key="" disabled>
      select location
    </option>,
  ];

  availableLocations.forEach((location, i) => {
    optionsLocations.push(
      <option key={i} value={location.identifier}>
        {location.store_type} - {location.slug} - {location.identifier}
      </option>,
    );
  });

  useEffectSkipFirst(() => {
    if (ENV_IS_BUILD) window.location.href = `https://${appDomain}.vinhood.dev/playground`;
  }, [appDomain]);

  const deleteUserFidelityCard = async () => {
    try {
      const resultAction = await UserUtils.deleteUserFidelityCardInfo();
      if (resultAction && resultAction.success) {
        handleResetKioskUserSession({ showFidelityMessage: true });
      }
    } catch (error) {
      console.error('Error deleting fidelity card:', error);
    }
  };

  return (
    <>
      {/* ? Allow force RTL */}
      <div className="panel collapsible" role="presentation">
        Service Config
      </div>

      <Box sx={{ margin: themeDevTools.spacing.medium }}>
        {ENV_IS_BUILD && (
          <DropdownSelect options={optionsServices} title="Service" onChange={e => setAppDomain(e.target.value)} />
        )}

        <DropdownSelect
          options={optionsLanguages}
          title="Locale"
          onChange={e => dispatch(setServiceLocale(e.target.value))}
        />

        <DropdownSelect
          isDisabled={isUserKioskAdmin}
          options={optionsLocations}
          title="Location"
          onChange={handleChangeRetailerLocation}
        />

        <DropdownSelect
          options={optionsProducts}
          title="Product"
          onChange={e => dispatch(setProductCategory(e.target.value))}
        />

        <DropdownSelect
          options={optionsPages}
          title="Page"
          onChange={e => history.navigate(prependBasename(e.target.value))}
        />

        <MultiSelectChips listItems={originalAddOns} onChange={setSelectedAddOn} />

        {/* // * Lock kiosk to 1080x1920 */}
        {isApplicationKiosk && (
          <div className="panel">
            <span className="title">Mock host</span>
            <input
              checked={isHostMocked}
              className="ml-1"
              name="Mock host"
              type="checkbox"
              onChange={e => {
                e.stopPropagation();
                dispatch(toggleMockHost(!isHostMocked));
              }}
            />
          </div>
        )}
        {fidelityCardId && (
          <>
            <div className="panel collapsible" role="presentation">
              Fidelity Card
            </div>
            <div style={{ margin: themeDevTools.spacing.medium }}>
              <p> Fidelity card scanned: {fidelityCardId}</p>
              {removableFidelityCards.includes(fidelityCardId) && (
                <Button onClick={() => deleteUserFidelityCard()}> DELETE </Button>
              )}
            </div>
          </>
        )}
      </Box>
    </>
  );
};
